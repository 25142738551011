import React from "react";
import { graphql } from "gatsby";
import AWS from "aws-sdk";
import { LayoutParser } from "@fishrmn/fishrmn-utils";
import {
  Hoo,
  Reviews,
  Location,
  ImageGallery,
  Call,
  ContactForm,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import SectionLayouts from "../components/SectionLayouts";

import HomeBackground from "../data/hero1.jpg";
import LocationBackground from "../data/hero2.jpg";
import ContactBackground from "../data/hero3.jpg";

import Gallery1 from "../data/gallery1.jpg";
import Gallery2 from "../data/gallery2.jpg";
import Gallery3 from "../data/gallery3.jpg";

const GalleryImages = [
  Gallery1,
  Gallery2,
  Gallery3,
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/45164653_10161360343701337_3412082394021232640_o.jpg?_nc_cat=104&_nc_ht=scontent.fnyc1-1.fna&oh=96803773c44257c3700f0d2e5c8ebc4d&oe=5C775476",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/45186782_10161360343416337_5701491569870241792_o.jpg?_nc_cat=105&_nc_ht=scontent.fnyc1-1.fna&oh=f8c0b6328e714cc24ac2dbee72d851ff&oe=5C8855D0",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/44243127_10161268997971337_7650324605244014592_o.jpg?_nc_cat=100&_nc_ht=scontent.fnyc1-1.fna&oh=3c8ec47eef5766b5d64dd7d857cc4522&oe=5C7182F0",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/43442105_10161188711386337_6412154107255062528_o.jpg?_nc_cat=101&_nc_ht=scontent.fnyc1-1.fna&oh=1a3972345be277c2266a3fdb8c368eea&oe=5C85F82B",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/43541446_10161188708831337_2630784781536722944_o.jpg?_nc_cat=105&_nc_ht=scontent.fnyc1-1.fna&oh=85cdbbf8c411642bc6821fc5e920ed72&oe=5C72B223",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/40841345_10160867155876337_8023383175357005824_o.jpg?_nc_cat=101&_nc_ht=scontent.fnyc1-1.fna&oh=93b2c9ca2db15c794edaecc0873c48c4&oe=5C7F62D8",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/41039240_10160886302226337_3350857291619368960_o.jpg?_nc_cat=107&_nc_ht=scontent.fnyc1-1.fna&oh=76f32225218e3e2c07ef4f20fde89732&oe=5C3D82DE",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/40751968_10160867158516337_1496360129115717632_o.jpg?_nc_cat=111&_nc_ht=scontent.fnyc1-1.fna&oh=3f1bf5e7db60b8f9bfe43b5d5e18c549&oe=5C3D6128",
  "https://scontent.fnyc1-1.fna.fbcdn.net/v/t1.0-9/40803318_10160867157966337_5423710385293230080_o.jpg?_nc_cat=108&_nc_ht=scontent.fnyc1-1.fna&oh=65cf605b7bb913a2173da44ebf8d0686&oe=5C83F313"
];

const params = (name, email, phone, about) => ({
  Destination: {
    /* required */
    ToAddresses: ["vbertolino@charter.net"]
  },
  Message: {
    /* required */
    Body: {
      /* required */
      Text: {
        Charset: "UTF-8",
        Data: `
          ${name} has reached out to you via your website contact form
          
          "${about}"
          Email: ${email}
          Phone: ${phone}
          
          By clicking reply to this email, the contacts email will be prepopulated 
          `
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: `Website contact form: ${name}`
    }
  },
  Source: "info@fishrmn.com",
  ReplyToAddresses: [email]
});

const subscriptionCallback = ({ name, phone, email, about }) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(params(name, email, phone, about))
    .promise();

export default class extends React.Component {
  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  render() {
    const { siteContent } = this.props.data.allAirtable.edges[0].node;
    const images = siteContent.Images.filter(image => image.url).map(
      image => image.url
    );
    const pageData = {
      about: siteContent.About,
      address: siteContent.Business_Address,
      phone: siteContent.Business_Phone,
      hoo:
        siteContent.Hours_of_Operation.length > 1 &&
        siteContent.Hours_of_Operation,
      pageTitle: `Welcome to ${siteContent.Business_Name}`,
      pageTitleBackgroundColor: "#3d3d3d",
      businessName: siteContent.Business_Name,
      images: images.length > 0 && images,
      timezone: "America/New_York",
      reviews: siteContent.Reviews.map(review => ({
        content: review.text,
        source: review.data_source
      }))
    };
    const sectionLayouts = LayoutParser().parsePageLayout(
      [
        <div>
          <div id="home">
            <div style={{ backgroundImage: `url('${HomeBackground}')` }}>
              <div>
                <h1>{pageData.businessName}</h1>
                <Call number={pageData.phone} />
              </div>
            </div>
            <div>
              <h1>Welcome</h1>
              <div>{pageData.about[1]} We are specialists in rehabs, roofing, siding, and handyman services.</div>
              {pageData.reviews.length > 0 && <h1>Customer Reviews</h1>}
              <div id="review-container">
                <div className="review-card">
                  "Vinny recently did a rehab job for one of my clients who bought a great home, that needed some repairs, and updates..."
                  <a href="https://www.facebook.com/pg/bertolinoconstruction/reviews/?ref=page_internal" target="_blank">See More</a>
                </div>
                <div className="review-card">
                  "Needed a window replaced in our condo's bathroom. Vin came out and fixed right away. Great work, on time and reasonably priced."
                  <a href="https://www.facebook.com/pg/bertolinoconstruction/reviews/?ref=page_internal" target="_blank">See More</a>
                </div>
              </div>
            </div>
          </div>
          <div id="location">
            <div style={{ backgroundImage: `url('${LocationBackground}')` }}>
              <div>
                <h1>Location</h1>
                <div>
                  <div>
                    <Hoo hours={pageData.hoo} timezone={pageData.timezone} showHours={false} />
                    <div style={{ textAlign: "center" }}>We are open 24/7</div>
                  </div>
                  <div>
                    <Location
                      address={pageData.address}
                      afterText=""
                      name={pageData.businessName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="gallery">
            <h1>Our Work</h1>
            <ImageGallery images={GalleryImages} imagesPerRow={3} />
          </div>
          <div id="contact">
            <div style={{ backgroundImage: `url('${ContactBackground}')` }}>
              <div>
                <h1>Contact</h1>
                <div id="emergency-service">24/7 Emergency Storm Service</div>
                <Call number={pageData.phone} />
                <h1>Get A Quote</h1>
                <ContactForm
                  subscriptionCallback={subscriptionCallback}
                  header=""
                  showTextArea
                  textAreaPlaceholder="Message"
                />
              </div>
            </div>
          </div>
        </div>
      ],
      pageData
    );
    return (
      <Layout location={this.props.location}>
        <SectionLayouts sectionLayouts={sectionLayouts} />
      </Layout>
    );
  }
}

export const query = graphql`
  query HomeQuery {
    allAirtable {
      edges {
        node {
          siteContent {
            Business_Address
            Business_Phone
            Hours_of_Operation
            Business_Name
            Images {
              url
            }
            About
            Reviews {
              text
              data_source
            }
          }
        }
      }
    }
  }
`;
